/** @jsx jsx */
import { jsx, Text } from 'theme-ui'
import { graphql, Link } from 'gatsby'
import { filterPostNodesFromEdges } from '../lib/helpers'
import Container from '../components/Container'
import GraphQLErrorList from '../components/GraphQLErrorList'
import SEO from '../components/SEO'
import LayoutContainer from '../containers/LayoutContainer'
import BlogPostPreviewList from '../components/BlogPostPreviewList'

export const query = graphql`
  query($slug: String!) {
    posts: allSanityPost(
      filter: { hiddenFromList: { ne: true }, series: { series: { slug: { eq: $slug } } } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          publishedAt
          categories {
            _id
            title
          }
          title
          slug {
            current
          }
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
          _rawBody(resolveReferences: { maxDepth: 5 })
          series {
            _key
            _type
            series {
              id
              title
              slug
            }
          }
        }
      }
    }
  }
`

const BlogPostTemplate = props => {
  const { data, errors } = props
  const postNodes = filterPostNodesFromEdges((data || {}).posts)
  const { title, description } = props.pageContext

  return (
    <LayoutContainer>
      {errors && <SEO title="GraphQL Error" />}
      {postNodes && <SEO title={title} description={description} lang={'en'} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {postNodes && (
        <Container>
          <Link
            to="/"
            sx={{
              color: 'black',
              opacity: 0.6,
              '&:hover': {
                opacity: 0.8,
              },
            }}
          >
            <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
              />
            </svg>
            <span hidden>Go Back Home</span>
          </Link>
          <Text
            sx={{
              mt: 6,
              fontSize: '4xl',
              fontWeight: 'black',
              color: 'black',
              textAlign: 'center',
              lineHeight: 'loose',
            }}
          >
            {title}
          </Text>
          <Text sx={{ opacity: 0.8, fontSize: 'lg', mb: 24, textAlign: 'center' }}>
            {description}
          </Text>
          <BlogPostPreviewList nodes={postNodes} />
        </Container>
      )}
    </LayoutContainer>
  )
}

export default BlogPostTemplate
